@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @media (min-width: 1024px) {
      .group:hover .group-hover\:rotate-y-180 {
        transform: rotateY(180deg);
      }
    }
    
    .flip .rotate-y-180 {
      transform: rotateY(180deg);
    }
  }